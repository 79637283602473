import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function W2SSolutions() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c10_b1202c38a8.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                W2S Solutions
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                W2S Solutions is a full-stack UI UX design and development
                agency that was established in 2010.
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $25-$49 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 10-49
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.w2ssolutions.com/" target="_blank">
                  https://www.w2ssolutions.com/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | 3rd Floor, No 5, 1st Cross Rd, K.R.Colony, Krishna
                  Reddy Colony, Domlur, Bengaluru, Karnataka 560071
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                W2S Solutions is a full-stack UI UX design and development
                agency that was established in 2010. Over 20 years in the
                industry, the company has built over 25 successful apps for both
                mobile and web platforms. The team mainly provides digital
                solutions for startups and mid-sized companies. The company has
                headquarters in Chennai but has since opened offices in Dublin,
                Austin, Calgary, Dubai, and Waterloo. The company has a
                reputation for thinking outside the box and coming up with
                unique ideas that help the client's business. The team has
                worked across various industries- healthcare, finance, real
                estate, automotive, logistics, agriculture, weather, etc. The
                team offers cloud integration with its apps and uses Natural
                language processing and Artificial Intelligence. Over the years,
                they have worked with Starbucks, Techmart, Samsung, Blackberry,
                Enmax, Paragon and Sony.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
